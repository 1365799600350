import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Box } from '@chakra-ui/react'
import { AuthenticatedRequestContext } from '../App'
import PharmacyEditor from '../components/PharmacyEditor'

const CreatePharmacy = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const createMutation = useMutation({
		mutationFn: (data) =>
			authenticatedRequest.post('/admin/pharmacies/create', data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['pharmacies'] })
			navigate('/pharmacies')
		}
	})

	return (
		<Box p="8">
			<PharmacyEditor title="Apotheke erstellen" mutation={createMutation} />
		</Box>
	)
}

export default CreatePharmacy
