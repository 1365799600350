import React, { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Box, Spinner, Alert, AlertIcon, useToast } from '@chakra-ui/react'
import DeleteItemDialog from '../components/DeleteItemDialog'
import { AuthenticatedRequestContext, CurrentUserContext } from '../App'
import PharmacyEditor from '../components/PharmacyEditor'

const EditPharmacyPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
	const currentUser = useContext(CurrentUserContext)
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const toast = useToast()
	const { pharmacyId } = useParams()

	const { isLoading, isError, data } = useQuery(
		['pharmacies', pharmacyId],
		() =>
			authenticatedRequest
				.get(`/admin/pharmacies/${pharmacyId}`)
				.then((response) => response.data),
		{ refetchOnWindowFocus: false }
	)

	const deleteMutation = useMutation({
		mutationFn: () =>
			authenticatedRequest.put(`/admin/pharmacies/${pharmacyId}/delete`),
		onSuccess: () => {
			toast({
				title: 'Apotheke gelöscht',
				description: 'Die Apotheke wurde erfolgreich gelöscht.',
				status: 'info',
				duration: 5000,
				isClosable: true
			})

			queryClient.invalidateQueries({ queryKey: ['pharmacies'] })
			navigate('/pharmacies')
		}
	})

	const updateMutation = useMutation({
		mutationFn: (values) =>
			authenticatedRequest.put(`/admin/pharmacies/${pharmacyId}/update`, {
				name: values.name,
				street: values.street,
				number: values.number,
				city: values.city,
				lat: values.lat,
				lng: values.lng,
				postCode: values.postCode,
				openingHours: values.openingHours,
				webLink: values.webLink,
				storeLink: values.storeLink,
				isHidden: values.isHidden,
				username: values.username,
				password: values.password
			}),
		onSuccess: () => {
			toast({
				title: 'Apotheke gespeichert',
				description: 'Die Änderungen wurden erfolgreich gespeichert.',
				status: 'success',
				duration: 5000,
				isClosable: true
			})

			if (currentUser.isAdmin) {
				queryClient.invalidateQueries({ queryKey: ['pharmacies'] })
				navigate('/pharmacies')
			} else {
				queryClient.invalidateQueries({ queryKey: ['pharmacies', pharmacyId] })
			}
		}
	})

	return (
		<Box p="8">
			{isError ? (
				<Alert status="error">
					<AlertIcon />
					Beim Laden der Daten ist ein Fehler aufgetreten.
				</Alert>
			) : isLoading ? (
				<Spinner />
			) : (
				<PharmacyEditor
					title="Apotheke bearbeiten"
					additionalActions={
						currentUser.isAdmin && (
							<DeleteItemDialog
								title="Apotheke löschen"
								mutation={deleteMutation}
								disabled={updateMutation.isLoading}
							/>
						)
					}
					mutation={updateMutation}
					data={data}
				/>
			)}
		</Box>
	)
}

export default EditPharmacyPage
