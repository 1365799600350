import React, { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Box, Spinner, Alert, AlertIcon, useToast } from '@chakra-ui/react'
import DeleteItemDialog from '../components/DeleteItemDialog'
import { AuthenticatedRequestContext } from '../App'
import CouponEditor from '../components/CouponEditor'

const EditCouponPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const toast = useToast()
	const { couponId } = useParams()

	const { isLoading, isError, data } = useQuery(
		['coupons', couponId],
		() =>
			authenticatedRequest
				.get(`/admin/coupons/${couponId}`)
				.then((response) => response.data),
		{ refetchOnWindowFocus: false }
	)

	const deleteMutation = useMutation({
		mutationFn: () =>
			authenticatedRequest.put(`/admin/coupons/${couponId}/delete`),
		onSuccess: () => {
			toast({
				title: 'Coupon gelöscht',
				description: 'Der Coupon wurde erfolgreich gelöscht.',
				status: 'info',
				duration: 5000,
				isClosable: true
			})

			queryClient.invalidateQueries({ queryKey: ['coupons'] })
			navigate('/coupons')
		}
	})

	const updateMutation = useMutation({
		mutationFn: (values) =>
			authenticatedRequest.put(`/admin/coupons/${couponId}/update`, {
				discountAmount: values.discountAmount,
				name: values.name,
				month: values.month,
				subject: values.subject,
				color: values.color,
				disclaimer: values.disclaimer,
				validFrom: values.validFrom,
				validUntil: values.validUntil
			}),
		onSuccess: () => {
			toast({
				title: 'Coupon gespeichert',
				description: 'Die Änderungen wurden erfolgreich gespeichert.',
				status: 'success',
				duration: 5000,
				isClosable: true
			})

			queryClient.invalidateQueries({ queryKey: ['coupons'] })
			navigate('/coupons')
		}
	})

	return (
		<Box p="8">
			{isError ? (
				<Alert status="error">
					<AlertIcon />
					Beim Laden der Daten ist ein Fehler aufgetreten.
				</Alert>
			) : isLoading ? (
				<Spinner />
			) : (
				<CouponEditor
					title="Coupon bearbeiten"
					additionalActions={
						<DeleteItemDialog
							title="Coupon löschen"
							mutation={deleteMutation}
							disabled={updateMutation.isLoading}
						/>
					}
					mutation={updateMutation}
					data={data}
				/>
			)}
		</Box>
	)
}

export default EditCouponPage
