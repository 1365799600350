import React from 'react'
import {
	Flex,
	Heading,
	Button,
	Stack,
	HStack,
	Card,
	CardBody,
	SimpleGrid,
	InputGroup,
	Input,
	InputRightElement,
	Textarea,
	FormLabel,
	FormControl,
	FormErrorMessage,
	useBreakpointValue
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { FloppyDisk } from 'phosphor-react'
import { objectIsEmpty } from '../util/data'
import CouponPreview from '../components/CouponPreview'

const CouponEditor = ({ title, additionalActions, mutation, data }) => {
	const isCouponCurrentlyActive = data
		? dayjs().isBetween(data.validFrom, data.validUntil, 'day', '[')
		: false

	return (
		<Formik
			initialValues={{
				name: data ? data.name : '',
				month: data ? data.month : '',
				discountAmount: data ? data.discountAmount : '',
				subject: data ? data.subject : '',
				validFrom: data ? data.validFrom : '',
				validUntil: data ? data.validUntil : '',
				disclaimer: data ? data.disclaimer : '',
				color: data ? data.color : '#63a2d8'
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Pflichtfeld'),
				month: Yup.string().required('Pflichtfeld'),
				discountAmount: Yup.number().positive().required('Pflichtfeld'),
				subject: Yup.string()
					.max(50, 'Kann nur maximal 50 Zeichen lang sein')
					.required('Pflichtfeld'),
				validFrom: Yup.date().required('Pflichtfeld'),
				validUntil: Yup.date().required('Pflichtfeld'),
				disclaimer: Yup.string()
					.max(500, 'Kann nur maximal 500 Zeichen lang sein')
					.required('Pflichtfeld'),
				color: Yup.string().required('Required')
			})}
			onSubmit={(data) => mutation.mutate(data)}
		>
			{({ values, errors, dirty, handleChange }) => (
				<Form>
					<Flex justifyContent="space-between">
						<Heading marginBottom="5">{title}</Heading>
						<HStack>
							{additionalActions}
							<Button
								type="submit"
								colorScheme="green"
								rightIcon={<FloppyDisk weight="bold" size={20} />}
								disabled={!dirty || !objectIsEmpty(errors)}
								isLoading={mutation.isLoading}
								loadingText="Wird gespeichert..."
							>
								Speichern
							</Button>
						</HStack>
					</Flex>
					<SimpleGrid columns={useBreakpointValue({ base: 1, lg: 2 })} gap={10}>
						<Card bg="white">
							<CardBody>
								{mutation.isError && (
									<Alert status="error" mb="2">
										<AlertIcon />
										Beim Speichern ist ein Fehler aufgetreten.
									</Alert>
								)}
								<Stack gap={2}>
									<HStack alignItems="flex-start">
										<FormControl isRequired isInvalid={errors.name}>
											<FormLabel>Name</FormLabel>
											<Input
												id="name"
												value={values.name}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.name && (
												<FormErrorMessage>{errors.name}</FormErrorMessage>
											)}
										</FormControl>
										<FormControl isRequired isInvalid={errors.month}>
											<FormLabel>Monat</FormLabel>
											<Input
												id="month"
												value={values.month}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.month && (
												<FormErrorMessage>{errors.month}</FormErrorMessage>
											)}
										</FormControl>
									</HStack>
									<FormControl isRequired isInvalid={errors.discountAmount}>
										<FormLabel>Rabatt</FormLabel>
										<InputGroup>
											<Input
												id="discountAmount"
												type="number"
												value={values.discountAmount}
												onChange={handleChange}
												disabled={mutation.isLoading}
												min="1"
											/>
											<InputRightElement children="%" />
										</InputGroup>
										{errors.discountAmount && (
											<FormErrorMessage>
												{errors.discountAmount}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isRequired isInvalid={errors.subject}>
										<FormLabel>Betreff</FormLabel>
										<Input
											id="subject"
											value={values.subject}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.subject && (
											<FormErrorMessage>{errors.subject}</FormErrorMessage>
										)}
									</FormControl>
									<HStack alignItems="flex-start">
										<FormControl isRequired isInvalid={errors.validFrom}>
											<FormLabel>Gültig von</FormLabel>
											<Input
												id="validFrom"
												type="date"
												value={dayjs(values.validFrom).format('YYYY-MM-DD')}
												onChange={handleChange}
												disabled={mutation.isLoading || isCouponCurrentlyActive}
											/>
											{errors.validFrom && (
												<FormErrorMessage>{errors.validFrom}</FormErrorMessage>
											)}
										</FormControl>
										<FormControl isRequired isInvalid={errors.validUntil}>
											<FormLabel>Gültig bis</FormLabel>
											<Input
												id="validUntil"
												type="date"
												value={dayjs(values.validUntil).format('YYYY-MM-DD')}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.validUntil && (
												<FormErrorMessage>{errors.validUntil}</FormErrorMessage>
											)}
										</FormControl>
									</HStack>
									<FormControl isRequired isInvalid={errors.disclaimer}>
										<FormLabel>Disclaimer</FormLabel>
										<Textarea
											id="disclaimer"
											value={values.disclaimer}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.disclaimer && (
											<FormErrorMessage>{errors.disclaimer}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isRequired isInvalid={errors.color}>
										<FormLabel>Farbe</FormLabel>
										<Input
											id="color"
											type="color"
											value={values.color}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.color && (
											<FormErrorMessage>{errors.color}</FormErrorMessage>
										)}
									</FormControl>
								</Stack>
							</CardBody>
						</Card>
						<Stack gap={1}>
							<Heading size="sm">Vorschau</Heading>
							<CouponPreview coupon={values} />
						</Stack>
					</SimpleGrid>
				</Form>
			)}
		</Formik>
	)
}

export default CouponEditor
