import React, { useContext } from 'react'
import {
	Box,
	HStack,
	Stack,
	Button,
	Avatar,
	Image,
	Text,
	useBreakpointValue
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ChartBar, Tag, MapPin, SignOut } from 'phosphor-react'
import { CurrentUserContext } from '../App'
import logoWhite from '../assets/logo_white.svg'

const Sidebar = ({ logoutUser }) => {
	const currentUser = useContext(CurrentUserContext)

	return (
		<Box
			width={useBreakpointValue({ md: '300px' })}
			height={useBreakpointValue({ md: '100vh' })}
			flexShrink="0"
			bg="brand.blue"
			boxShadow="md"
			padding="5"
		>
			<Stack
				display="flex"
				flexDir="column"
				justifyContent="space-between"
				height="stretch"
			>
				<Stack gap={5}>
					<Image src={logoWhite} maxW="150px" />
					<Stack>
						<MenuItem to="/" icon={<ChartBar size={25} />}>
							Dashbord
						</MenuItem>
						{currentUser.isAdmin ? (
							<>
								<MenuItem to="/coupons" icon={<Tag size={25} />}>
									Coupons
								</MenuItem>
								<MenuItem to="/pharmacies" icon={<MapPin size={25} />}>
									Apotheken
								</MenuItem>
							</>
						) : (
							<MenuItem
								to={`/pharmacy/${currentUser.pharmacy.id}`}
								icon={<MapPin size={25} />}
							>
								Apotheke bearbeiten
							</MenuItem>
						)}
					</Stack>
				</Stack>
				<Stack gap={3}>
					<HStack alignItems="center" gap={1}>
						<Avatar
							name={
								currentUser.isAdmin
									? 'Administrator'
									: currentUser.pharmacy.name
							}
						/>
						<Text color="white">
							{currentUser.isAdmin
								? 'Administrator'
								: currentUser.pharmacy.name}
						</Text>
					</HStack>
					<Button
						leftIcon={<SignOut size={25} />}
						variant="inverted"
						onClick={logoutUser}
					>
						Abmelden
					</Button>
				</Stack>
			</Stack>
		</Box>
	)
}

const MenuItem = ({ to, icon, children }) => (
	<Link to={to}>
		<Button leftIcon={icon} variant="inverted" width="100%">
			{children}
		</Button>
	</Link>
)

export default Sidebar
