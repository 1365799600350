import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
	fonts: {
		heading: `'Open Sans', sans-serif`,
		body: `'Open Sans', sans-serif`
	},
	colors: {
		brand: {
			blue: '#00264b',
			blue_light: '#63a2d8',
			red: '#d7181f'
		}
	},
	components: {
		Button: {
			variants: {
				solid: {
					background: 'brand.blue',
					color: 'white',
					_hover: {
						background: 'brand.blue_light',
						color: 'white'
					},
					_focus: {
						background: 'brand.blue_light'
					}
				},
				inverted: {
					background: 'white',
					color: 'brand.blue',
					_hover: {
						background: 'brand.blue_light',
						color: 'white'
					}
				},
				danger: {
					background: 'brand.red',
					color: 'white',
					_hover: {
						background: 'brand.blue_light',
						color: 'white'
					}
				}
			}
		}
	}
})

export default theme
