import React, { useState, useEffect, useMemo } from 'react'
import { Routes, Route } from 'react-router-dom'
import axios from 'axios'
import LoginPage from './pages/Login'
import DashboardPage from './pages/Dashboard'
import CouponsPage from './pages/Coupons'
import EditCouponPage from './pages/EditCoupon'
import PharmaciesPage from './pages/Pharmacies'
import PageNotFound from './pages/PageNotFound'
import Layout from './components/Layout'
import CreateCoupon from './pages/CreateCoupon'
import EditPharmacyPage from './pages/EditPharmacy'
import CreatePharmacy from './pages/CreatePharmacy'

export const AuthenticatedRequestContext = React.createContext(null)
export const CurrentUserContext = React.createContext(null)

function App() {
	const [token, setToken] = useState(false)

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken')
		if (accessToken) setToken(accessToken)
	})

	const logoutUser = () => {
		localStorage.removeItem('accessToken')
		setToken(false)
	}

	const axiosInstance = useMemo(
		() =>
			axios.create({
				baseURL: import.meta.env.VITE_APP_API_BASE_URL,
				headers: {
					Authorization: `Bearer ${token}`
				}
			}),
		[token]
	)

	if (token) {
		return (
			<AuthenticatedRequestContext.Provider value={axiosInstance}>
				<div className="App">
					<Layout logoutUser={logoutUser}>
						<Routes>
							<Route exact path="/" element={<DashboardPage />} />
							<Route exact path="/coupons" element={<CouponsPage />} />
							<Route
								exact
								path="/coupons/:couponId"
								element={<EditCouponPage />}
							/>
							<Route exact path="/coupons/create" element={<CreateCoupon />} />
							<Route exact path="/pharmacies" element={<PharmaciesPage />} />
							<Route
								exact
								path="/pharmacy/:pharmacyId"
								element={<EditPharmacyPage />}
							/>
							<Route
								exact
								path="/pharmacies/create"
								element={<CreatePharmacy />}
							/>
							<Route path="*" element={<PageNotFound />} />
						</Routes>
					</Layout>
				</div>
			</AuthenticatedRequestContext.Provider>
		)
	}

	return <LoginPage setToken={setToken} />
}

export default App
