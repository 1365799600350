import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import App from './App'
import theme from './util/theme'
import './styles/global.css'
import 'mapbox-gl/dist/mapbox-gl.css'

dayjs.extend(isBetween)
dayjs.extend(localeData)
dayjs.locale('de')

const queryClient = new QueryClient()

Sentry.init({
  dsn: 'https://5890bafd525e7c524d3300922a5d058c@o4508443604942848.ingest.de.sentry.io/4508443606057040',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.8,
  tracePropagationTargets: [import.meta.env.VITE_APP_API_BASE_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</ChakraProvider>
		</QueryClientProvider>
	</React.StrictMode>
)
