import { Flex, Stack, Image, Heading, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.svg'

const PageNotFound = () => (
  <Flex justifyContent="center" alignItems="center" height="100vh" p="8">
    <Stack gap="4" alignItems="center">
      <Image src={logo} width="200px" />
      <Heading>404 – Seite nicht gefunden</Heading>
      <Link to="/">
        <Button>Zurück zur Startseite</Button>
      </Link>
    </Stack>
  </Flex>
)

export default PageNotFound
