import React, { useRef, useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl'
import { Box, useBreakpointValue } from '@chakra-ui/react'

const LocationMap = ({ lat, lng }) => {
	const mapContainer = useRef(null)
	const map = useRef(null)
	const [marker, setMarker] = useState(null)

	useEffect(() => {
		if (map.current) return

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			accessToken: import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN,
			style: 'mapbox://styles/mapbox/streets-v12',
			locale: 'de',
			cooperativeGestures: true,
			scrollZoom: false,
			center: [lng, lat],
			zoom: 13
		})

		map.current.addControl(new mapboxgl.NavigationControl())
		map.current.on('load', () => map.current.flyTo({ center: [lng, lat] }))
	})

	useEffect(() => {
		if (!map.current) return

		if (marker) marker.remove()

		const newMarker = new mapboxgl.Marker({ color: '#d7181f' })
			.setLngLat([lng, lat])
			.addTo(map.current)

		setMarker(newMarker)

		map.current.flyTo({ center: [lng, lat] })
	}, [lat, lng])

	return <Box height="350px" width="100%" ref={mapContainer} />
}

export default LocationMap
