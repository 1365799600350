import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import {
	Box,
	Flex,
	Heading,
	Button,
	Spinner,
	Alert,
	AlertIcon,
	Table,
	Thead,
	Tbody,
	Th,
	Tr,
	Td,
	Card,
	CardBody,
	Tooltip
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { PlusCircle, Eye, EyeSlash } from 'phosphor-react'
import { AuthenticatedRequestContext } from '../App'

const PharmaciesPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)

	const { isLoading, isRefetching, isError, data } = useQuery(
		['pharmacies'],
		() =>
			authenticatedRequest
				.get('/admin/pharmacies')
				.then((response) => response.data),
		{ staleTime: 60000 }
	)

	return (
		<Box p="8">
			<Flex justifyContent="space-between">
				<Heading marginBottom="5">
					Apotheken{isRefetching && <Spinner ml="3" />}
				</Heading>
				<Link to="/pharmacies/create">
					<Button rightIcon={<PlusCircle weight="bold" size={20} />}>
						Apotheke erstellen
					</Button>
				</Link>
			</Flex>
			{isError ? (
				<Alert status="error">
					<AlertIcon />
					Beim Laden der Daten ist ein Fehler aufgetreten.
				</Alert>
			) : isLoading ? (
				<Spinner />
			) : (
				<Card bg="white">
					<CardBody overflowX="auto">
						{data.length ? (
							<Table>
								<Thead>
									<Tr>
										<Th>Name</Th>
										<Th>Straße & Hausnummer</Th>
										<Th>Postleitzahl & Ort</Th>
										<Th>Sichtbarkeit</Th>
										<Th>Erstellungsdatum</Th>
										<Th>Zuletzt aktualisiert</Th>
									</Tr>
								</Thead>
								<Tbody>
									{data.map((pharmacy, index) => (
										<Tr key={index}>
											<Td>
												<Link to={`/pharmacy/${pharmacy.id}`}>
													{pharmacy.name}
												</Link>
											</Td>
											<Td>
												{pharmacy.street} {pharmacy.number}
											</Td>
											<Td>
												{pharmacy.postCode} {pharmacy.city}
											</Td>
											<Td>
												<Tooltip
													hasArrow
													label={
														`Apotheke ${pharmacy.isHidden ? 'ausgeblendet' : 'eingeblendet'}`
													}
												>
													{pharmacy.isHidden ? <EyeSlash weight="bold" /> : <Eye weight="bold" />}
												</Tooltip>
											</Td>
											<Td>
												{dayjs(pharmacy.createdAt).format('DD. MMM YYYY')}
											</Td>
											<Td>
												{dayjs(pharmacy.updatedAt).format('DD. MMM YYYY')}
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						) : (
							<Alert>
								<AlertIcon />
								Keine Apotheken gefunden.
							</Alert>
						)}
					</CardBody>
				</Card>
			)}
		</Box>
	)
}

export default PharmaciesPage
