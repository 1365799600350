import React, { useRef } from 'react'
import {
	Button,
	Alert,
	AlertIcon,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure
} from '@chakra-ui/react'
import { Trash } from 'phosphor-react'

const DeleteItemDialog = ({ title, mutation, disabled }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	return (
		<>
			<Button
				variant="danger"
				rightIcon={<Trash weight="bold" size={20} />}
				onClick={onOpen}
				disabled={disabled}
			>
				Löschen
			</Button>

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{title}
						</AlertDialogHeader>

						<AlertDialogBody>
							{mutation.isError && (
								<Alert status="error" mb="2">
									<AlertIcon />
									Beim Löschen ist ein Fehler aufgetreten.
								</Alert>
							)}
							Sind Sie sicher? Sie können diese Aktion nicht mehr rückgängig
							machen.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								onClick={onClose}
								disabled={mutation.isLoading || disabled}
							>
								Abbrechen
							</Button>
							<Button
								variant="danger"
								onClick={() => mutation.mutate()}
								isLoading={mutation.isLoading || disabled}
								loadingText="Wird gelöscht..."
								ml={2}
							>
								Löschen
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}

export default DeleteItemDialog
