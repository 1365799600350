import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
	Flex,
	Box,
	Text,
	Spinner,
	useBreakpointValue,
	Button
} from '@chakra-ui/react'
import { AuthenticatedRequestContext, CurrentUserContext } from '../App'
import Sidebar from './Sidebar'

const Layout = ({ logoutUser, children }) => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
	const breakpointValue = useBreakpointValue({ base: 'column', md: 'row' })

	const { isLoading, isError, isSuccess, data, refetch } = useQuery(
		['currentUser'],
		() =>
			authenticatedRequest
				.get('/admin/me')
				.then((response) => response.data)
				.catch((error) => {
					if (error.response && error.response.status === 403) {
						logoutUser()
					}

					return Promise.reject(error)
				}),
		{ refetchOnWindowFocus: false }
	)

	return (
		<Flex
			bg="gray.100"
			height="100vh"
			justifyContent={!isSuccess && 'center'}
			alignItems={!isSuccess && 'center'}
			gap={!isSuccess && '5'}
			flexDirection={!isSuccess ? 'column' : breakpointValue}
		>
			{isLoading ? (
				<Spinner size="xl" color="brand.blue" />
			) : isError ? (
				<>
					<Text>Beim Laden der Daten ist ein Fehler aufgetreten.</Text>
					<Button onClick={refetch}>Erneut versuchen</Button>
				</>
			) : (
				<CurrentUserContext.Provider value={data}>
					<Sidebar logoutUser={logoutUser} />
					<Box as="main" flexGrow="1" overflowX="auto">
						{children}
					</Box>
				</CurrentUserContext.Provider>
			)}
		</Flex>
	)
}

export default Layout
