import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

const CouponPreview = ({ coupon }) => (
	<Flex
		bg={coupon.color.length ? coupon.color : 'brand.blue_light'}
		flexDirection="column"
		maxW="550px"
	>
		<Box
			bg="brand.blue"
			color="white"
			fontWeight="light"
			textTransform="uppercase"
			width="100%"
			padding="6px 10px"
		>
			{coupon.name}&nbsp;<strong>{coupon.month}</strong>
		</Box>
		<Flex
			justifyContent="space-between"
			alignItems="center"
			gap="5"
			color="white"
			padding="0 10px"
		>
			<Text fontSize="100px" fontWeight="bold">
				{coupon.discountAmount ? coupon.discountAmount : 0}%
			</Text>
			<Stack>
				<Text fontSize="25px" lineHeight="1.2">
					{coupon.subject}
				</Text>
				<Text>
					Gültig bis{' '}
					{coupon.validUntil && dayjs(coupon.validUntil).format('DD.MM.YYYY')}
				</Text>
			</Stack>
		</Flex>
	</Flex>
)

export default CouponPreview
