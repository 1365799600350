import React, { useEffect, useState, useContext } from 'react'
import {
	Flex,
	Box,
	Heading,
	Button,
	IconButton,
	Stack,
	HStack,
	SimpleGrid,
	Card,
	CardBody,
	Input,
	Checkbox,
	FormLabel,
	FormControl,
	FormErrorMessage,
	Tooltip,
	Divider,
	Alert,
	AlertIcon
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { useFormikContext, Formik, Form, FieldArray } from 'formik'
import { FloppyDisk, MapTrifold, Plus, Trash } from 'phosphor-react'
import { CurrentUserContext } from '../App'
import { objectIsEmpty, timeRegex } from '../util/data'
import LocationMap from './LocationMap'

const PharmacyEditor = ({ title, additionalActions, mutation, data }) => {
	const currentUser = useContext(CurrentUserContext)

	return (
		<Formik
			initialValues={{
				name: data ? data.name : '',
				street: data ? data.street : '',
				number: data ? data.number : '',
				postCode: data ? data.postCode : '',
				city: data ? data.city : '',
				webLink: data ? data.webLink : '',
				storeLink: data ? data.storeLink : null,
				isHidden: data ? data.isHidden : false,
				lat: data ? data.lat : 52.375893,
				lng: data ? data.lng : 9.73201,
				openingHours:
					data && data.openingHours
						? data.openingHours
						: {
								monday: [{ start: '00:00', end: '00:00' }],
								tuesday: [{ start: '00:00', end: '00:00' }],
								wednesday: [{ start: '00:00', end: '00:00' }],
								thursday: [{ start: '00:00', end: '00:00' }],
								friday: [{ start: '00:00', end: '00:00' }],
								saturday: [{ start: '00:00', end: '00:00' }],
								sunday: [{ start: '00:00', end: '00:00' }]
						  },
				username: data ? data.loginUsername : '',
				password: ''
			}}
			validationSchema={Yup.object({
				name: Yup.string().required('Pflichtfeld'),
				street: Yup.string().required('Pflichtfeld'),
				number: Yup.string().required('Pflichtfeld'),
				postCode: Yup.number().required('Pflichtfeld'),
				city: Yup.string().required('Pflichtfeld'),
				openingHours: Yup.object().shape({
					[Yup.mixed()]: Yup.array().of(
						Yup.object().shape({
							start: Yup.string().matches(timeRegex, 'Formatierung ungültig'),
							end: Yup.string().matches(timeRegex, 'Formatierung ungültig')
						})
					)
				}),
				webLink: Yup.string().url('Kein valider Link').required('Pflichtfeld'),
				storeLink: Yup.string().nullable().url('Kein valider Link')
			})}
			onSubmit={(data) => mutation.mutate(data)}
		>
			{({ values, errors, dirty, handleChange }) => (
				<Form>
					<Flex justifyContent="space-between">
						<Heading marginBottom="5">{title}</Heading>
						<HStack>
							{additionalActions}
							<Button
								type="submit"
								colorScheme="green"
								rightIcon={<FloppyDisk weight="bold" size={20} />}
								disabled={!dirty || !objectIsEmpty(errors)}
								isLoading={mutation.isLoading}
								loadingText="Wird gespeichert..."
							>
								Speichern
							</Button>
						</HStack>
					</Flex>
					<SimpleGrid columns={2} gap={10}>
						<Card bg="white">
							<CardBody>
								{mutation.isError && (
									<Alert status="error" mb="2">
										<AlertIcon />
										Beim Speichern ist ein Fehler aufgetreten.
									</Alert>
								)}
								<Stack gap={2}>
									<FormControl isRequired isInvalid={errors.name}>
										<FormLabel>Name</FormLabel>
										<Input
											id="name"
											value={values.name}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.name && (
											<FormErrorMessage>{errors.name}</FormErrorMessage>
										)}
									</FormControl>
									<HStack alignItems="flex-start">
										<FormControl isRequired isInvalid={errors.street}>
											<FormLabel>Straße</FormLabel>
											<Input
												id="street"
												value={values.street}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.street && (
												<FormErrorMessage>{errors.street}</FormErrorMessage>
											)}
										</FormControl>
										<FormControl isRequired isInvalid={errors.number}>
											<FormLabel>Hausnummer</FormLabel>
											<Input
												id="number"
												value={values.number}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.number && (
												<FormErrorMessage>{errors.number}</FormErrorMessage>
											)}
										</FormControl>
									</HStack>
									<HStack alignItems="flex-start">
										<FormControl isRequired isInvalid={errors.postCode}>
											<FormLabel>Postleitzahl</FormLabel>
											<Input
												id="postCode"
												value={values.postCode}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.postCode && (
												<FormErrorMessage>{errors.postCode}</FormErrorMessage>
											)}
										</FormControl>
										<FormControl isRequired isInvalid={errors.city}>
											<FormLabel>Ort</FormLabel>
											<Input
												id="city"
												value={values.city}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
											{errors.city && (
												<FormErrorMessage>{errors.city}</FormErrorMessage>
											)}
										</FormControl>
									</HStack>
									<FormControl isRequired isInvalid={errors.webLink}>
										<FormLabel>Webseite</FormLabel>
										<Input
											id="webLink"
											value={values.webLink}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.webLink && (
											<FormErrorMessage>{errors.webLink}</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.storeLink}>
										<FormLabel>Onlineshop</FormLabel>
										<Input
											id="storeLink"
											value={values.storeLink}
											onChange={handleChange}
											disabled={mutation.isLoading}
										/>
										{errors.storeLink && (
											<FormErrorMessage>{errors.storeLink}</FormErrorMessage>
										)}
									</FormControl>
									<HStack alignItems="flex-start">
										<FormControl isRequired isInvalid={errors.lat}>
											<FormLabel>Latitude</FormLabel>
											<Input
												id="lat"
												value={values.lat}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
										</FormControl>
										<FormControl isRequired isInvalid={errors.lng}>
											<FormLabel>Longitude</FormLabel>
											<Input
												id="lng"
												value={values.lng}
												onChange={handleChange}
												disabled={mutation.isLoading}
											/>
										</FormControl>
									</HStack>
									<Checkbox
										id="isHidden"
										isChecked={values.isHidden}
										onChange={handleChange}
										disabled={mutation.isLoading}
									>
										<Tooltip hasArrow label="Apotheke in der App ausblenden">
											Ausgeblendet
										</Tooltip>
									</Checkbox>
									<Stack>
										<LocationMap lat={values.lat} lng={values.lng} />
										<Box>
											<Button
												leftIcon={<MapTrifold weight="bold" size={20} />}
												loadingText="Wird verarbeitet..."
											>
												Koordinaten aus Adresse generieren
											</Button>
										</Box>
									</Stack>
									{currentUser.isAdmin && (
										<>
											<Divider />
											<HStack>
												<FormControl isInvalid={errors.username}>
													<FormLabel>Benutzername</FormLabel>
													<Input
														id="username"
														maxLength="10"
														autoComplete="off"
														value={values.username}
														onChange={handleChange}
														disabled={mutation.isLoading}
													/>
												</FormControl>
												<FormControl isInvalid={errors.password}>
													<FormLabel>Passwort</FormLabel>
													<Input
														id="password"
														type="password"
														autoComplete="off"
														value={values.password}
														onChange={handleChange}
														disabled={mutation.isLoading}
													/>
												</FormControl>
											</HStack>
										</>
									)}
								</Stack>
							</CardBody>
						</Card>
						<Card bg="white">
							<CardBody>
								<Stack gap="2">
									<OpeningHoursDay
										day="Montag"
										id="monday"
										values={values.openingHours.monday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Dienstag"
										id="tuesday"
										values={values.openingHours.tuesday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Mittwoch"
										id="wednesday"
										values={values.openingHours.wednesday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Donnerstag"
										id="thursday"
										values={values.openingHours.thursday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Freitag"
										id="friday"
										values={values.openingHours.friday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Samstag"
										id="saturday"
										values={values.openingHours.saturday}
										handleChange={handleChange}
										mutation={mutation}
									/>
									<OpeningHoursDay
										day="Sonntag"
										id="sunday"
										values={values.openingHours.sunday}
										handleChange={handleChange}
										mutation={mutation}
									/>
								</Stack>
							</CardBody>
						</Card>
					</SimpleGrid>
				</Form>
			)}
		</Formik>
	)
}

const OpeningHoursDay = ({ day, id, values, handleChange, mutation }) => {
	const [isClosed, setIsClosed] = useState(false)
	const { setFieldValue } = useFormikContext()

	useEffect(
		() => setIsClosed(values[0].start === '00:00' && values[0].end === '00:00'),
		[]
	)

	useEffect(() => {
		if (isClosed) {
			setFieldValue(`openingHours.${id}.[0].start`, '00:00')
			setFieldValue(`openingHours.${id}.[0].end`, '00:00')
		}
	}, [isClosed])

	return (
		<Box>
			<FormLabel>{day}</FormLabel>
			<Stack>
				<Checkbox
					isChecked={isClosed}
					onChange={(event) => setIsClosed(event.target.checked)}
				>
					Geschlossen
				</Checkbox>
				<FieldArray
					name={`openingHours.${id}`}
					render={(arrayHelpers) =>
						values.map((item, index) => (
							<HStack key={index}>
								<FormControl>
									<Input
										name={`openingHours.${id}.${index}.start`}
										value={values[index] ? values[index].start : '00:00'}
										placeholder="Öffnet um"
										onChange={handleChange}
										disabled={mutation.isLoading || isClosed}
									/>
								</FormControl>
								<FormControl>
									<Input
										name={`openingHours.${id}.${index}.end`}
										value={values[index] ? values[index].end : '00:00'}
										placeholder="Schließt um"
										onChange={handleChange}
										disabled={mutation.isLoading || isClosed}
									/>
								</FormControl>
								<IconButton
									icon={<Trash weight="bold" size={20} />}
									disabled={index === 0 || isClosed}
									variant="outline"
									onClick={() => arrayHelpers.remove(index, '')}
								/>
								<IconButton
									icon={<Plus weight="bold" size={20} />}
									disabled={
										index + 1 !== values.length ||
										2 === values.length ||
										isClosed
									}
									variant="outline"
									onClick={() =>
										arrayHelpers.push({ start: '00:00', end: '00:00' })
									}
								/>
							</HStack>
						))
					}
				/>
			</Stack>
		</Box>
	)
}

export default PharmacyEditor
