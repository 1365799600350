import { useMutation } from '@tanstack/react-query'
import {
	Box,
	Button,
	Container,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Flex,
	Image,
	Alert,
	AlertIcon,
	useBreakpointValue,
	useColorModeValue
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import loginUser from '../api/login'
import logo from '../assets/logo.svg'

const LoginPage = ({ setToken }) => {
	const { mutate, isLoading, isError, error } = useMutation({
		mutationFn: loginUser,
		onSuccess: (response) => {
			localStorage.setItem('accessToken', response.data.accessToken)
			setToken(response.data.accessToken)
		}
	})

	return (
		<Box
			bg={useBreakpointValue({ base: 'transparent', sm: 'gray.100' })}
			height="100vh"
		>
			<Container
				maxW="lg"
				py={{ base: '12', md: '24' }}
				px={{ base: '0', sm: '8' }}
			>
				<Stack spacing="8">
					<Flex justifyContent="center">
						<Image src={logo} maxW="225" />
					</Flex>
					<Box
						py={{ base: '0', sm: '8' }}
						px={{ base: '4', sm: '10' }}
						bg={useBreakpointValue({ base: 'transparent', sm: 'white' })}
						boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
						borderRadius={{ base: 'none', sm: 'xl' }}
					>
						{isError && (
							<Alert status="error" mb="2">
								<AlertIcon />
								{error.code === 'ERR_BAD_REQUEST'
									? 'Benutzername oder Passwort falsch.'
									: 'Es ist ein Fehler aufgetreten.'}
							</Alert>
						)}
						<Formik
							initialValues={{
								username: '',
								password: ''
							}}
							onSubmit={mutate}
						>
							{({ values, dirty, handleChange }) => (
								<Form>
									<Stack spacing="6">
										<Stack spacing="5">
											<FormControl>
												<FormLabel htmlFor="username">Benutzername</FormLabel>
												<Input
													id="username"
													value={values.username}
													onChange={handleChange}
													disabled={isLoading}
													required
												/>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="password">Passwort</FormLabel>
												<Input
													id="password"
													type="password"
													value={values.password}
													onChange={handleChange}
													disabled={isLoading}
													required
												/>
											</FormControl>
										</Stack>
										<Stack spacing="6">
											<Button
												type="submit"
												disabled={!dirty}
												isLoading={isLoading || isLoading}
												loadingText="Einloggen..."
											>
												Einloggen
											</Button>
										</Stack>
									</Stack>
								</Form>
							)}
						</Formik>
					</Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default LoginPage
