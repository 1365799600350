import React from 'react'
import {
	Box,
	Heading,
	Card,
	CardBody,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Tabs,
	TabList,
	Tab,
	TabPanel,
	TabPanels
} from '@chakra-ui/react'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import dayjs from 'dayjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export const StatsDiagram = ({ heading, data, labels }) => {
	return (
		<Box>
			<Heading size="md" marginBottom="5">
				{heading}
			</Heading>
			<Card bg="white" height="350px">
				<CardBody>
					<Bar
						options={{
							responsive: true,
							maintainAspectRatio: false
						}}
						data={{
							labels: labels,
							datasets: [
								{
									data: data.map((month) => month.count),
									backgroundColor: '#63a2d8'
								}
							]
						}}
					/>
				</CardBody>
			</Card>
		</Box>
	)
}

export const MonthlyRedemptionsStatsTable = ({ heading, data }) => (
	<Box>
		<Heading size="md" marginBottom="5">
			{heading}
		</Heading>
		<Card bg="white">
			<CardBody>
				<Table>
					<Thead>
						<Tr>
							<Th>Jahr</Th>
							<Th>Monat</Th>
							<Th>Anzahl</Th>
						</Tr>
					</Thead>
					<Tbody>
						{data.map((month, index) => (
							<Tr key={index}>
								<Td>{month.year}</Td>
								<Td>
									{dayjs()
										.month(month.month - 1)
										.format('MMMM')}
								</Td>
								<Td>{month.count ? month.count : 0}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	</Box>
)

export const RedemptionsByPharmacyStatsTable = ({ heading, data }) => (
	<Box>
		<Tabs variant="unstyled">
			<TabList border="0" marginBottom="5">
				<Tab
					padding="0"
					paddingRight="5"
					_selected={{ color: 'brand.blue_light' }}
				>
					<Heading size="md">Im letzten Monat</Heading>
				</Tab>
				<Tab
					padding="0"
					paddingRight="5"
					_selected={{ color: 'brand.blue_light' }}
				>
					<Heading size="md">Im letzten Jahr</Heading>
				</Tab>
			</TabList>

			<Card bg="white">
				<CardBody>
					<TabPanels>
						<TabPanel padding="0">
							<Table>
								<Thead>
									<Tr>
										<Th>Apotheke</Th>
										<Th>Anzahl</Th>
									</Tr>
								</Thead>
								<Tbody>
									{data.lastMonth.map((pharmacy, index) => (
										<Tr key={index}>
											<Td>{pharmacy.Pharmacy.name}</Td>
											<Td>{pharmacy.count}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TabPanel>

						<TabPanel padding="0">
							<Table>
								<Thead>
									<Tr>
										<Th>Apotheke</Th>
										<Th>Anzahl</Th>
									</Tr>
								</Thead>
								<Tbody>
									{data.lastYear.map((pharmacy, index) => (
										<Tr key={index}>
											<Td>{pharmacy.Pharmacy.name}</Td>
											<Td>{pharmacy.count}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TabPanel>
					</TabPanels>
				</CardBody>
			</Card>
		</Tabs>
	</Box>
)
