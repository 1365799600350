import dayjs from 'dayjs'

export const getLastTwelveMonths = () => {
	let current = dayjs().subtract(12, 'month')
	let finalData = []

	while (current.diff(dayjs(), 'month') < 0) {
		current = current.add(1, 'month')

		finalData.push({ month: current.month() + 1, year: current.year() })
	}

	return finalData
}

export const mergeDataWithMonths = (months, data) => {
	let finalData = []

	data.forEach((item) => {
		const index = months.findIndex(
			(month) => month.year === item.year && month.month === item.month
		)

		if (index !== -1) {
			finalData[index] = { ...months[index], ...item }
		} else {
			finalData.push(item)
		}
	})

	return finalData
}
