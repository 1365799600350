import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Box } from '@chakra-ui/react'
import { AuthenticatedRequestContext } from '../App'
import CouponEditor from '../components/CouponEditor'

const CreateCoupon = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const createMutation = useMutation({
		mutationFn: (data) =>
			authenticatedRequest.post('/admin/coupons/create', data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['coupons'] })
			navigate('/coupons')
		}
	})

	return (
		<Box p="8">
			<CouponEditor title="Coupon erstellen" mutation={createMutation} />
		</Box>
	)
}

export default CreateCoupon
