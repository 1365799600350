import React, { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
	Box,
	Stack,
	SimpleGrid,
	Heading,
	Alert,
	AlertIcon,
	Spinner,
	Card,
	CardBody,
	Stat,
	StatLabel,
	StatNumber,
	useBreakpointValue
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { AuthenticatedRequestContext, CurrentUserContext } from '../App'
import { getLastTwelveMonths, mergeDataWithMonths } from '../util/chart'
import {
	StatsDiagram,
	MonthlyRedemptionsStatsTable,
	RedemptionsByPharmacyStatsTable
} from '../components/Statistics'

const DashboardPage = () => {
	const authenticatedRequest = useContext(AuthenticatedRequestContext)

	const { isLoading, isRefetching, isError, data } = useQuery(
		['statistics'],
		() =>
			authenticatedRequest
				.get('/admin/statistics')
				.then((response) => response.data),
		{ staleTime: 15000, refetchInterval: 60000 }
	)

	return (
		<Box p="8">
			<Heading marginBottom={5}>
				Dashboard{isRefetching && <Spinner ml="3" />}
			</Heading>
			{isError ? (
				<Alert status="error">
					<AlertIcon />
					Beim Laden der Daten ist ein Fehler aufgetreten.
				</Alert>
			) : isLoading ? (
				<Spinner />
			) : (
				<Stats data={data} />
			)}
		</Box>
	)
}

const Stats = ({ data }) => {
	const currentUser = useContext(CurrentUserContext)
	const lastTwelveMonths = useMemo(getLastTwelveMonths)
	const lastTwelveMonthNames = useMemo(
		() =>
			lastTwelveMonths.map((month) =>
				dayjs()
					.month(month.month - 1)
					.format('MMM')
			),
		[lastTwelveMonths]
	)

	const couponRedemptions = mergeDataWithMonths(
		lastTwelveMonths,
		data.couponRedemptions
	)
	const userRegistrations = mergeDataWithMonths(
		lastTwelveMonths,
		data.userRegistrations
	)

	return (
		<Stack gap={6}>
			<SimpleGrid
				columns={useBreakpointValue({
					base: 1,
					lg: currentUser.isAdmin ? 4 : 2
				})}
				gap={10}
			>
				<Card bg="white">
					<CardBody>
						<Stat>
							<StatLabel>Insgesamt eingelöste Coupons</StatLabel>
							<StatNumber>{data.totalCouponRedemptions}</StatNumber>
						</Stat>
					</CardBody>
				</Card>
				{currentUser.isAdmin && (
					<Card bg="white">
						<CardBody>
							<Stat>
								<StatLabel>Insgesamt erstellte Coupons</StatLabel>
								<StatNumber>{data.totalCoupons}</StatNumber>
							</Stat>
						</CardBody>
					</Card>
				)}
				<Card bg="white">
					<CardBody>
						<Stat>
							<StatLabel>Insgesamt registrierte Nutzer</StatLabel>
							<StatNumber>{data.totalUserRegistrations}</StatNumber>
						</Stat>
					</CardBody>
				</Card>
				{currentUser.isAdmin && (
					<Card bg="white">
						<CardBody>
							<Stat>
								<StatLabel>Insgesamt erstelle Apotheken</StatLabel>
								<StatNumber>{data.totalPharmacies}</StatNumber>
							</Stat>
						</CardBody>
					</Card>
				)}
			</SimpleGrid>
			{currentUser.isAdmin && data.totalHiddenPharmacies > 0 && (
				<Card bg="white" overflow="hidden">
					<Alert
						status={
							data.totalHiddenPharmacies > Math.round(data.totalPharmacies / 2)
								? 'warning'
								: 'info'
						}
					>
						<AlertIcon />
						{data.totalHiddenPharmacies} von {data.totalPharmacies} Apotheken
						sind aktuell in der App ausgeblendet.
					</Alert>
				</Card>
			)}
			<SimpleGrid columns={useBreakpointValue({ base: 1, lg: 2 })} gap={10}>
				<StatsDiagram
					heading="Eingelöste Coupons"
					data={couponRedemptions}
					labels={lastTwelveMonthNames}
				/>
				<StatsDiagram
					heading="Neu registrierte Nutzer"
					data={userRegistrations}
					labels={lastTwelveMonthNames}
				/>
				<MonthlyRedemptionsStatsTable
					heading="Eingelöste Coupons"
					data={couponRedemptions}
				/>
				{currentUser.isAdmin ? (
					<RedemptionsByPharmacyStatsTable
						data={data.couponRedemptionsByPharmacy}
					/>
				) : (
					<Box>
						<Heading size="md" marginBottom="5">
							Im letzten Monat
						</Heading>
						<Card bg="white">
							<CardBody>
								<Stat>
									<StatLabel>
										Eingelöst in {currentUser.pharmacy.name}
									</StatLabel>
									<StatNumber>
										{data.couponRedemptionsByPharmacy.length
											? data.couponRedemptionsByPharmacy[0].count
											: 0}
									</StatNumber>
								</Stat>
							</CardBody>
						</Card>
					</Box>
				)}
			</SimpleGrid>
		</Stack>
	)
}

export default DashboardPage
